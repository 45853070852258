import { CircularProgress, Typography } from '@material-ui/core';
import React, { Dispatch } from 'react';
import * as yup from 'yup';
import { useAuthContext } from '../components/authContext';
import DateTime from '../components/dateTime';
import FooterButton from '../components/footerButton';
import { InteractionBadge } from '../components/interactionBadge';
import Layout from '../components/layout';
import LoadingWrapper from '../components/loadingWrapper';
import SelectButtonGroup from '../components/selectButtonGroup';
import TextInput from '../components/textInput';
import { FormActions, FormState, useNewForm } from '../forms/form';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import useConfig from '../hooks/useConfig';
import { Suggestions, useSuggestions } from '../hooks/useSuggestions';
import { Collaboration } from '../icons';
import { generateId } from '../services/entity';
import { Interaction, defaultSchema } from '../services/interactions';
import {
  FORM_TYPE_TITLE,
  FormConfiguration,
  FormType,
} from '../services/lists';
import { BaseProps } from '../typings/types';

export const schema = yup.object<Interaction>({
  ...defaultSchema,
  subtype: yup.string().required(),
  clientAdvocacy: yup.string().required(),
  outcomes: yup.array(yup.string()).required(),
  actions: yup.array(yup.string()).required(),
  comments: yup.string().required(),
  tally: yup.mixed(), // Override tally as not required
});

interface PureNewCollaborationProps extends FormState {
  config?: FormConfiguration;
  dispatch: Dispatch<FormActions>;
  gettingConfig: boolean;
  loggingIn: boolean;
  suggestions: Suggestions;
}

export const PureNewCollaboration: React.FC<PureNewCollaborationProps> = ({
  config,
  gettingConfig,
  loggingIn,
  dispatch,
  submitting,
  submitted,
  suggestions,
  ...formState
}) => {
  const isValid = schema.isValidSync(formState);
  const {
    timeOccurred,
    subtype,
    clientAdvocacy,
    outcomes,
    actions,
    comments,
    error,
  } = formState;

  const defaultConfig = config ? config : {};

  return (
    <Layout
      title={FORM_TYPE_TITLE.Collaboration}
      headerIcon={
        <InteractionBadge type={FormType.Collaboration}>
          <Collaboration />
        </InteractionBadge>
      }
    >
      <LoadingWrapper
        loading={gettingConfig || loggingIn}
        submitting={submitting}
        hasConfig={!!config}
        submitted={submitted}
        error={error}
      >
        <form
          data-testid="loaded"
          autoComplete="off"
          onSubmit={(e): void => {
            e.preventDefault();
            dispatch({
              type: 'SUBMIT',
            });
          }}
        >
          <DateTime
            label="Time"
            id="time-occurred"
            name="time-occurred"
            value={timeOccurred}
            onChange={(date): void => {
              dispatch({ type: 'CHANGE_TIME_OCCURRED', timeOccurred: date });
            }}
          />

          <SelectButtonGroup
            id="type"
            options={defaultConfig.subtypes}
            suggestOther={suggestions.suggestSubtype}
            label="Event type"
            value={subtype}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_SUBTYPE',
                subtype: value as string,
              });
            }}
          />

          <SelectButtonGroup
            id="advocacy"
            options={defaultConfig.advocacyTypes}
            label="Agency / Advocacy"
            value={clientAdvocacy}
            suggestOther={suggestions.suggestClientAdvocacy}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_CLIENT_ADVOCACY',
                clientAdvocacy: value as string,
              });
            }}
          />

          <SelectButtonGroup
            id="outcome"
            label="Issues discussed"
            options={defaultConfig.outcomes}
            suggestOther={suggestions.suggestOutcome}
            value={outcomes}
            multi
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_INTERACTION_OUTCOME',
                outcomes: value as string[],
              });
            }}
          />

          <SelectButtonGroup
            id="agreed-actions"
            label="Agreed actions"
            multi
            value={actions}
            suggestOther={suggestions.suggestAction}
            onChange={(value): void => {
              dispatch({
                type: 'CHANGE_ACTION',
                actions: value as string[],
              });
            }}
            options={defaultConfig.actions}
          />

          <TextInput
            label="Comments"
            id="comments"
            name="comments"
            value={comments}
            multiline
            rows={3}
            onChange={(e): void => {
              dispatch({
                type: 'CHANGE_COMMENTS',
                comments: e.target.value,
              });
            }}
          />

          <FooterButton type="submit" disabled={!isValid || submitting}>
            {submitting ? (
              <>
                <CircularProgress />
                <Typography variant="srOnly">SUBMITTING</Typography>
              </>
            ) : (
              'SUBMIT'
            )}
          </FooterButton>
        </form>
      </LoadingWrapper>
    </Layout>
  );
};

export const NewCollaboration: React.FC<BaseProps> = ({ location }) => {
  const state = !!location.state ? location.state : {};
  const pathname = location.pathname;
  const { getUser, loggingIn } = useAuthContext();
  const { config, gettingConfig } = useConfig();
  useAuthRouteProtection(pathname);
  const collaborationConfig = config?.items[FormType.Collaboration];
  const useNewInteractionResult = useNewForm({
    getUser,
    formType: FormType.Collaboration,
    existingId: !state?.id ? generateId() : state.id,
    config: collaborationConfig,
  });
  const suggestions = useSuggestions(getUser);
  return (
    <PureNewCollaboration
      {...useNewInteractionResult}
      config={collaborationConfig}
      gettingConfig={gettingConfig}
      loggingIn={loggingIn}
      suggestions={suggestions}
    />
  );
};
export default NewCollaboration;
